.paginationInfo {
  padding: 0px;
  list-style: none;
  text-align: center;
  margin-top: 28px;
  li {
    display: inline-block;
    vertical-align: middle;
    a {
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      display: block;
      text-decoration: none;
      border-radius: 50%;
      color: $primary-color;
      overflow: hidden;
    }
    span {
      font-size: 29px;
      display: block;
      height: 30px;
      text-align: center;
      width: 30px;
      line-height: 30px;
    }
  }
  .active {
    .page-link {
      height: 30px;
      width: 30px;
      text-align: center;
      line-height: 30px;
      display: block;
      text-decoration: none;
      border-radius: 50%;
      color: $white;
      font-size: 12px;
      background-color: $primary-color;
    }
  }
  .sr-only {
    display: none;
  }
  .disabled .page-link {
    pointer-events: none;
    opacity: 0.3;
  }
}
.infoContainer.MuiGrid-container {
  background: white;
  padding: 15px;
  .MuiPaper-rounded {
    border-radius: 0;
    box-shadow: none;
  }
}

.message-box {
  text-align: left;
  background: transparent;
  .MuiIconButton-sizeSmall {
    color: black !important;
  }
}
.search {
  text-align: right;
  background: transparent;
}
