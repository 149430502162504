.alert-msg {
  margin-top: 10px;
}

.loader-body {
  text-align: center;
  width: 100%;
  img {
    width: 40px;
    margin: 20px auto;
    display: block;
  }
}

// Initiated Research Call
.MuiTableRow-root.I {
  background-color: #ffffff;
}

// Modify Research Call
.MuiTableRow-root.M {
  background-color: #ffffd4;
}

// Close Research call
.MuiTableRow-root.C {
  background-color: #ffeed4;
  pointer-events: none;
  td {
    color: rgba(0, 0, 0, 0.5);
    .MuiIconButton-root {
      color: rgba(0, 0, 0, 0.2) !important;
    }
  }
}

// Publish or push Research call
.MuiTableRow-root.P {
  background-color: aquamarine;

  td {
    color: rgba(0, 0, 0, 0.8);
    pointer-events: none;
    .MuiIconButton-root {
      color: rgba(0, 0, 0, 0.3) !important;
    }
    &:last-child {
      pointer-events: auto;
      button[aria-label="edit"],
      button[aria-label="close"] {
        color: rgba(0, 0, 0, 1) !important;
      }
      button[aria-label="publish"] {
        pointer-events: none;
      }
    }
  }
}

.ToolbarP .actionBTN .MuiIconButton-root.Mui-disabled {
  color: #ccc !important;
}

// Popup
.MuiDialog-container {
  .MuiDialog-paperWidthSm {
    max-width: 900px;
    position: relative;
    .MuiTypography-h6 {
      color: #3f51b5;
      font-size: 1.5rem;
      padding-right: 50px;
    }
    .MuiDialogActions-root {
      position: absolute;
      right: 0;
      top: 10px;
    }
    .MuiDialogContent-root {
      padding: 16px 24px;
    }
    .MuiButton-containedPrimary {
      margin: 0 10px 0 0;
    }
    .form-btn {
      padding-left: 0;
    }
  }
}

// Menu Link
.menuLink {
  a {
    text-decoration: none;
  }
}

.MuiAlert-root {
  &.alert-msg {
    max-width: none;
    width: auto;
    display: inline-flex;
    margin: 0;

    &.login {
      margin-top: 10px;
    }
  }
}
.signin-container button.btn {
  margin-top: 0 !important;
  padding: 3px !important;
  color: black !important;
}

.addDatasection {
  .alert-msg {
    margin-top: 10px;
  }
}

.mainSection {
  display: flex;
}
#addCall,
#modifyResearch {
  .MuiFormHelperText-root {
    color: red !important;
    position: static !important;
  }
}

.passwordWithMask {
  position: relative;
  .eye-icon {
    position: absolute;
    right: 0;
    top: 20px;
    cursor: pointer;
  }
}

.MuiList-root {
  a {
    cursor: pointer;
  }
}
