.addDatasection {
  .form-btn {
    display: block;
    width: 100%;
    padding-left: 10px;
    margin: 20px 0px 0px;
  }
  .formW {
    width: 18%;
    margin: 0px 1%;
    margin-bottom: 0px;
    position: relative;
    &.fullwidth {
      width: 100%;
      float: left;
    }
    .MuiFormLabel-root {
      font-size: 15px;
    }
  }
  .MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 8px;
  }
  .MuiPaper-root {
    position: absolute;
    top: 48px;
    z-index: 9;
    width: 100%;
    box-shadow: none;
    ul {
      list-style: none;
      padding: 0px;
      margin: 0;
      border-radius: $radius;
      max-height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
        0px 8px 10px 1px rgba(0, 0, 0, 0.14),
        0px 3px 14px 2px rgba(0, 0, 0, 0.12);
      li {
        padding: 10px;
        &:hover {
          background-color: $common-hover;
          cursor: pointer;
        }
      }
    }
    &.alert-msg {
      position: static;
    }
  }
  .MuiFormHelperText-root.Mui-error {
    color: #f44336;
    position: absolute;
    bottom: -22px;
  }
}
.clapse {
  .MuiExpansionPanelSummary-content {
    .changeBTN {
      display: block;
      transition: 1m;
    }
  }
  .Mui-expanded {
    .changeBTN {
      display: none !important;
      transition: 1m;
    }
  }
}
.MuiButton-containedPrimary {
  color: #fff;
  background-color: #596edc;
}
#query-helper-text {
  position: absolute;
  top: 48px;
}

.formW {
  .autocomplete-list-item {
    font-size: 12px;
    color: #909090;
    border-top: 1px solid #e8eaf5;
  }
  .autocomplete-list-item img {
    max-width: 60px;
    display: block;
    margin-bottom: 5px;
    max-height: 30px;
  }

  .autocomplete-list-item span {
    border: 1px solid #e8eaf5;
    font-size: 10px;
    padding: 3px;
    border-radius: 4px;
    margin-left: 5px;
  }
}

.MuiSelect-select {
  &.MuiSelect-select {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.MuiTableCell-body {
  .MuiIconButton-root {
    color: rgba(0, 0, 0, 0.54) !important;
    padding: 6px !important;
  }
}
.form2 {
  width: 100% !important;
}
.formF {
  width: 48% !important;
  textarea {
    width: 100%;
  }
}
.cradio {
  .MuiIconButton-root {
    color: $theme-font-color-LightOne !important;
  }
  .Mui-checked {
    color: $theme-font-color-secondary !important;
  }
  span.MuiFormControlLabel-label {
    font-size: 14px;
  }
}
.Cheading {
  font-weight: 600;
  margin-bottom: 5px;
  width: 100%;
  font-size: 18px;
  margin: 0px;
  margin: 30px 0px 15px;
}
.Mheading {
  font-weight: 600;
  margin-bottom: 5px;
  width: 100%;
  font-size: 25px;
  margin: 0px 0px 15px;
}
.pl20 {
  padding-left: 10px;
}
.tooztip {
  padding: 10px !important;
  padding-left: 20px !important;
  padding-bottom: 0px;
  li {
    font-size: 10px;
    margin-bottom: 5px;
  }
}
.box-shadow {
  box-shadow: $box-shadow;
}
.inner_filler
{
  padding: 20px 0px;
  /* margin-bottom: 50px; */
  display: inline-block;
  float: right;
  .form2 
  {
    width: 100px !important;
    float: left;
    margin-right: 20px;
    .MuiInput-root 
    {
      width: 100% !important;
    }
  }
}
.summary 
{
  width: 80%;
  padding: 20px;
  th 
  {
    padding: 12px 9px;
    font-size: 12px;
  }
}