// //
// // Basic Bootstrap table
// //

// .table {
//   width: 100%;
//   margin-bottom: 10px;
//   color: $table-color;
//   background-color: $table-bg; // Reset for nesting within parents with `background-color`.

//   th,
//   td {
//     padding: $table-cell-padding;
//     vertical-align: top;
//     border-top: $table-border-width solid $table-border-color;
//   }

//   thead th {
//     vertical-align: bottom;
//     border-bottom: (2 * $table-border-width) solid $table-border-color;
//   }

//   tbody + tbody {
//     border-top: (2 * $table-border-width) solid $table-border-color;
//   }
// }

// //
// // Condensed table w/ half padding
// //

// .table-sm {
//   th,
//   td {
//     padding: $table-cell-padding-sm;
//   }
// }

// // Border versions
// //
// // Add or remove borders all around the table and between all the columns.

// .table-bordered {
//   border: $table-border-width solid $table-border-color;

//   th,
//   td {
//     border: $table-border-width solid $table-border-color;
//   }

//   thead {
//     th,
//     td {
//       border-bottom-width: 2 * $table-border-width;
//     }
//   }
// }

// .table-borderless {
//   th,
//   td,
//   thead th,
//   tbody + tbody {
//     border: 0;
//   }
// }

// // Zebra-striping
// //
// // Default zebra-stripe styles (alternating gray and transparent backgrounds)

// .table-striped {
//   tbody tr:nth-of-type(#{$table-striped-order}) {
//     background-color: $table-accent-bg;
//   }
// }

// // Hover effect
// //
// // Placed here since it has to come after the potential zebra striping

// .table-hover {
//   tbody tr {
//     @include hover {
//       color: $table-hover-color;
//       background-color: $table-hover-bg;
//     }
//   }
// }

// // Table backgrounds
// //
// // Exact selectors below required to override `.table-striped` and prevent
// // inheritance to nested tables.

// @each $color, $value in $theme-colors {
//   @include table-row-variant(
//     $color,
//     theme-color-level($color, $table-bg-level),
//     theme-color-level($color, $table-border-level)
//   );
// }

// @include table-row-variant(active, $table-active-bg);

// // Dark styles
// //
// // Same table markup, but inverted color scheme: dark background and light text.

// // stylelint-disable-next-line no-duplicate-selectors
// .table {
//   .thead-dark {
//     th {
//       color: $table-dark-color;
//       background-color: $table-dark-bg;
//       border-color: $table-dark-border-color;
//     }
//   }

//   .thead-light {
//     th {
//       color: $table-head-color;
//       background-color: $table-head-bg;
//       border-color: $table-border-color;
//     }
//   }
// }

// .table-dark {
//   color: $table-dark-color;
//   background-color: $table-dark-bg;

//   th,
//   td,
//   thead th {
//     border-color: $table-dark-border-color;
//   }

//   &.table-bordered {
//     border: 0;
//   }

//   &.table-striped {
//     tbody tr:nth-of-type(odd) {
//       background-color: $table-dark-accent-bg;
//     }
//   }

//   &.table-hover {
//     tbody tr {
//       @include hover {
//         color: $table-dark-hover-color;
//         background-color: $table-dark-hover-bg;
//       }
//     }
//   }
// }

// // Responsive tables
// //
// // Generate series of `.table-responsive-*` classes for configuring the screen
// // size of where your table will overflow.

// .table-responsive {
//   @each $breakpoint in map-keys($grid-breakpoints) {
//     $next: breakpoint-next($breakpoint, $grid-breakpoints);
//     $infix: breakpoint-infix($next, $grid-breakpoints);

//     &#{$infix} {
//       @include media-breakpoint-down($breakpoint) {
//         display: block;
//         width: 100%;
//         overflow-x: auto;
//         -webkit-overflow-scrolling: touch;

//         // Prevent double border on horizontal scroll due to use of `display: block;`
//         > .table-bordered {
//           border: 0;
//         }
//       }
//     }
//   }
// }

.mainC {
  // padding: 30px;
  box-shadow: none !important;
  .MuiTableCell-body {
    color: #62687e;
  }
  .widthrowal {
    padding-left: 40px;
    box-shadow: $box-shadow;
    padding: 0px 0px 25px 0px;
    width: 95%;
    float: right;
    border-radius: $radius;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    tbody {
      td {
        padding: 12px 9px;
      }
    }
    th {
      padding: 12px 9px;
    }
    .headerONE {
      th {
        font-weight: bold;

        &:first-child {
          border-right: 1px solid #ddd;
          color: $theme-font-color-secondary;
        }
      }
      background-color: #e8eaf5;
    }
    .Cbtn {
      background-color: $primary-color;
      float: right;
      margin-top: 30px;
      right: 20px;
      &:hover {
        // background-color: $primary-color-dark;
      }
    }
  }
}
.listContainer {
  box-shadow: $box-shadow;
  padding: px 0px 0px 0px;
  border-radius: $radius;

  h4 {
    margin: 13px 8px;
  }
}
.profile {
  margin-bottom: 50px;
  box-shadow: $box-shadow;
  border-radius: $radius;
  .actionB {
    position: absolute;
    right: 44px;
    top: 44px;
    background: #109297;
    border-radius: 50%;
    box-shadow: $box-shadow;
    button {
      color: #189296 !important;
    background: #fff;
    }
    .MuiSvgIcon-root {
      font-size: 17px;
    }
  }
  .name {
    display: inline-block;
    position: absolute;
    top: 40px;
    font-weight: bold;
    padding-left: 15px;
    padding-top: 3px;
    color: #fff;
    b {
      color: $theme-font-color-LightOne;
    }
    span {
      display: block;
      font-size: 14px;
      font-weight: 700;
    }
  }
  .proofileS {
    background-color: #159296;
    height: 120px;
    position: relative;
    border-radius: $radius;

    .proofileP {
      overflow: hidden;
      position: absolute;
      padding-left: 20px;
      width: 100%;
      bottom: -40px;
      padding-bottom: 10px;
      .profic {
        height: 90px;
        width: 90px;
        border-radius: $radius;
        // border-radius: 15%;
        border: 3px solid #fff;
        // box-shadow: $box-shadow;
        background-color: #fff;
        float: left;
        position: relative;
        display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
        img 
        {
          width: 100%;
          bottom: 0;
          border-radius: $radius;
        }
      }
    }
  }
  .rating-box {
    color: #f0f0f0;
    display: block;
    float: left;
    background: #191c27;
    border-radius: 4px;
    height: 18px;
    font-size: 12px;
    padding: 3px 5px;
    top: 5px;
    position: relative;
  }

  .rating-star {
    font-size: 3rem;
    width: 14px;
    height: 3rem;
    // padding: 0 2rem;
    position: relative;
    display: block;
    float: left;
  }

  .full-star:before {
    color: #f2b01e;
    content: "\2605";
    position: absolute;
    left: 0;
    overflow: hidden;
  }

  .empty-star:before {
    content: "\2605";
    position: absolute;
    left: 0;
    overflow: hidden;
  }

  .half-star:before {
    color: #f2b01e;
    content: "\2605";
    width: 50%;
    position: absolute;
    left: 0;
    overflow: hidden;
  }

  .half-star:after {
    content: "\2605";
    position: absolute;
    left: 1.5rem;
    width: 50%;
    text-indent: -1.5rem;
    overflow: hidden;
  }
}

.Pinfo {
  margin-top: 50px;
  padding-bottom: 20px;
  .tbl {
    display: inline-block;
    width: 100%;
    padding: 20px;
    border-right: 1px dotted #e8eaf5;
    vertical-align: text-top;
    padding-top: 0px;
    overflow: hidden;
    .hd_P
    {
      font-size: 17px;
    background: #F5F6FA;
    /* margin: 0; */
    padding: 11px;
    border-radius: 4px;
    }
    .hd_PP
    {
      font-size: 16px;
    // background: #F5F6FA;
    /* margin: 0; */
    padding: 11px;
    border-radius: 4px;
    margin: 0px;
    }
  }

  table {
    td {
      padding: 7px 9px;
      border-color: #f5f6fa;
      &:first-child {
        font-weight: bold;
        color: $theme-font-color-secondary;
        width: 178px;
      }
    }
  }
}
.fund {
  padding-bottom: 0px !important;
  .MuiTypography-h6 {
    color: $theme-font-color-secondary !important;
  }
}
.tblC {
  td {
    padding: 12px 0px;
    &:first-child {
      font-weight: bold;
      color: $theme-font-color-secondary;
      padding-right: 40px;
    }
  }
  #fundREQ {
    .MuiFormHelperText-root {
      position: absolute;
      bottom: -31px;
      color: red;
      line-height: 12px;
    }
    position: relative;
    .formW {
      padding-right: 87px;
    }
    .form-btn {
      position: absolute;
      right: -11px;
      bottom: 9px;
    }
  }
  .fundtr {
    td {
      border-bottom: 0px;
    }
  }
  .success-msg {
    color: green;
  }
}
.forP 
{
  padding: 30px;
  background-color: #fff;
  Button 
  {
    margin-top:20px;
  }
  .Cheading
  {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
  }

}
.forPpp 
{
  .Cheading
  {
    font-size: 20px;
    margin-top: 0px;
    margin-bottom: 10px;
  }
  .form2
  {
    width: auto !important;
    margin-right: 25px;
  }
   
}
.admin 
{
  .formW
  {
    width: 21% !important;
    margin-right: 25px;
  }
  .form-btn
  {
    width: 100% !important;
  }
}
.daat
{
  width: 100% !important;
}
.mrb200
{
  margin-bottom: 30px !important;
}
h6.logo 
{
  flex-grow: 1;

}
.dataMenu 
{
  margin:0px;
  li 
  {
    display: inline-block !important;
    padding: 0px;
    margin-left: 10px;
  }
}