.signup {
  overflow: scroll;
  label 
  {
    text-transform: capitalize;
  }
  .formW {
    width: 45%;
    float: left;
    margin-left: 10px;
    margin-right: 10px;
    &.fullwidth {
      width: 100%;
      float: left;
      text-align: left;
      margin: 8px 10px;
    }
    .MuiFormControl-root {
      display: flex;
    }
    .MuiFormControl-marginNormal {
      margin: 8px 0;
    }
  }
  .fr 
  {
    width: 100%;
  }
  .akBTN {
    display: block;
    width: 100%;
    clear: both;
  }
  .categories {
    display: block;
    margin-bottom: 0;
  }
  .heading {
    font-weight: 600;
    color: $theme-font-color-secondary;
  }
  .rdBTN {
    display: block;
  }
  .uploadfile {
    display: inline-block;
    .block {
      display: block;
      color: #191c27;
      padding: 4px 0;
      text-transform: uppercase;
    }
  }
}
.difbtn
{
  text-align: center;
}