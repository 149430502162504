.commonS {
  padding: 0px !important;
}
.signin-container {
  padding: 0px !important;
  background-image: url(../../images/loginback.jpg);
  background-size: cover;
  max-width: 100%;
  margin: 0px !important;
  min-height: 100vh;
  display: block;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  overflow: scroll;
  form {
    width: 100%;
  }

  .FormikField {
    margin: 0px 0px 25px 0;
  }
  .signin-box {
    padding: 20px;

    .alert-msg {
      margin-top: 10px;
      display: flex;
    }
    .makeStyles-root-14 {
      position: relative;
      /* width: 90%; */
      margin-top: 13px;
      .MuiAlert-action {
        position: absolute;
        top: -17px;
        right: 0;
        color: red;

        .MuiAlert-icon {
          padding-right: 15px;
        }
      }
      .MuiSvgIcon-fontSizeInherit {
        color: #303030;
        position: absolute;
      }
    }
  }
  button {
    display: block;
    padding: 3px 18px;
    font-size: 14px;
    margin-top: 25px;
    width: 100%;
  }
  .content_side_userLS {
    h1 {
      color: $white;
      font-size: 22px;
      font-weight: 400;
    }
    img {
      width: 250px;
    }
  }
  .difbtn
  {
    p 
    {
      font-size: 15px;
    }
  .signuplink {
    // text-decoration: none;
    text-transform: capitalize;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.54);
    &:hover {
      text-decoration: underline;
    }
  }
}
}

.Mui-required.MuiFormHelperText-root {
  color: red;
  position: absolute;
  bottom: -20px;
}
.wdsection {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0px !important;
  padding: 0px !important;
}
.headingTXT {
  font-size: 39px !important;
  margin-top: 0px;
}

